.checklist-create-page {
  height: 100%;
  padding: 0 2px;
}
.direction-ltr {
  direction: ltr;
}

.record-table {
  .ant-table-title {
    background-color: #fafafa;
  }
}

.sort-list-item {
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 0;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  box-sizing: border-box;
  &:last-child {
    border-bottom: none;
  }
  .ant-radio {
    margin: 0 10px;
  }
  .ant-radio-button-wrapper {
    border: none;
    padding: 0;
    height: 13px;
    line-height: 13px;
  }
  .ant-checkbox + span {
    width: 180px;
  }
  .ant-radio-button-wrapper-checked {
    border: none;
  }
  .ant-radio-group {
    width: 15px;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    content: none;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
}

.custom-clear-icon:focus {
  .ant-select-clear {
    position: absolute;
    opacity: 1;
    z-index: 999;
  }
}
