.executive-dashboard-page {
    .page-header {
        margin-bottom: 0 !important;
        margin-right: 29px;
    }
    .filter-container {
        margin: 10px 0 0 0 !important;
        background: #ffffff;
        border: 1px solid #e1e4e5;
        box-sizing: border-box;
        border-radius: 4px;
        padding-top: 16px;
    }
    .chart-container {
        padding-top: 24px;
    }
    .card-container p {
        margin: 0;
    }
    .card-container > .ant-tabs-card .ant-tabs-content {
        // height: 120px;
        margin-top: -16px;
    }
    .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
        padding: 16px;
        background: #fff;
        border-left: 1px solid #f0f0f0;
        border-right: 1px solid #f0f0f0;
        border-bottom: 1px solid #f0f0f0;
        border-radius: 4px;
        box-sizing: border-box;
    }
 
    .card-container > .ant-tabs-card .ant-tabs-tab,
    [data-theme="compact"] .card-container > .ant-tabs-card .ant-tabs-tab {
        background: #EBECF0;
        // border-color: transparent;
    }
    .card-container > .ant-tabs-card .ant-tabs-tab-active,
    [data-theme="compact"] .card-container > .ant-tabs-card .ant-tabs-tab-active {
        background: #fff;
        border-color: #fff;
    }
    #components-tabs-demo-card-top .code-box-demo {
        padding: 24px;
        overflow: hidden;
        background: #f5f5f5;
    }
    [data-theme="compact"] .card-container > .ant-tabs-card .ant-tabs-content {
        // height: 120px;
        margin-top: -8px;
    }
    [data-theme="dark"] .card-container > .ant-tabs-card .ant-tabs-tab {
        background: transparent;
        border-color: transparent;
    }
    [data-theme="dark"] #components-tabs-demo-card-top .code-box-demo {
        background: #000;
    }
    [data-theme="dark"] .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
        background: #141414;
    }
    [data-theme="dark"] .card-container > .ant-tabs-card .ant-tabs-tab-active {
        background: #141414;
        border-color: #141414;
    }
    .project-info {
        .label {
            font-size: 12px;
            color: #83898A;
            opacity: 0.8;
        }
        .value {
            font-size: 15px;
            color: #17171A;
        }
    }
}
