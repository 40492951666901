@import "~bootstrap/scss/bootstrap";
@import "reboot.scss";

@import "antd/dist/antd.css";
@import "utils";
@import "layout";
@import "background";

@import "dashboard/all-projects";
@import "dashboard/project-detail";
@import "dashboard/executive-dashboard";

@import "form-builder/checklist-create";
@import "form-builder/checklist-parser";
@import "module-storage";

@import "checklist-record/record";