.tophat-sider {
    &.ant-layout-sider-collapsed {
        .logo {
            .site-logo-text {
                display: none;
            }
        }
    }
    .logo {
        margin: 18px;
        color: green;
        text-align: center;
        .site-logo-text {
            margin-top: 7px;
            margin-left: 13px;
        }
    }
    .ant-menu-inline {
        border-right: none;
        .ant-menu-item::after {
            left: 0;
            right: auto;
        }
    }
    .ant-menu-item-selected {
        border-right: 1px solid #e1e4e5;
    }
}

.site-layout {
    // for antd Drawer
    .site-drawer-render-in-current-wrapper {
        position: relative;
    }
    .site-main {
        padding: 20px 25px;
        background-color: #fafbfc;
        max-height: calc(100vh - 61px);
        height: 100%;
        .page-header {
            text-transform: capitalize;
            font-weight: bold;
            font-size: 18px;
            letter-spacing: 0.02em;
            margin-bottom: 10px;
        }
    }
    .ant-layout-content {
        background-color: #fff;
        border-left: 1px solid #e1e4e5;
    }
    .site-header {
        padding: 17px 25px;
        border-bottom: 1px solid #e1e4e5;
        display: flex;
        justify-content: space-between;
        .site-header-action {
            .ant-divider {
                margin: 0 15px;
            }
            .ant-dropdown-link {
                margin-left: 10px;
                text-decoration: none;
            }
            .ant-dropdown-trigger {
                color: #17171a;
                .anticon {
                    margin-left: 5px;
                }
            }
        }
        .ant-breadcrumb {
            .ant-breadcrumb-link {
                text-transform: capitalize;
            }
            & > span:last-child {
                color: #0f99f5;
            }
        }
    }
}

.site-header-user-menu {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 320px;
    height: 196px;
    background: #ffffff;
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    .user-menu-name {
        margin: 5px 0;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.02em;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #17171a;
        text-align: center;
    }
    .user-menu-email {
        font-weight: 500;
        font-size: 12px;
        line-height: 130%;
        letter-spacing: 0.02em;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #83898a;
        text-align: center;
    }
    .user-menu-devider {
        margin: 16px 0;
    }
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 48px 68px;
    background: #ffffff;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.12), 0px 15px 35px rgba(60, 66, 87, 0.08);
    border-radius: 4px;
}

.anticon {
    img,
    svg {
        vertical-align: inherit;
    }
}
.custom-clear-autocomplete {
    .ant-input {
        padding-right: 22px;
    }
    .ant-select-clear {
        display: none;
    }
    .custom-clear-btn {
        position: absolute;
        right: 53px;
        top: 9px;
        color: rgba(0, 0, 0, 0.25);
        z-index: 1;
    }
}

.ant-btn-primary:focus {
    border-color: #1890ff;
    background: #1890ff;
}

.custom-clear-select-btn {
    position: absolute;
    color: rgba(0, 0, 0, 0.25);
}